import ListItemButton from '@mui/material/ListItemButton';
import React, { useEffect, useLayoutEffect, useMemo, useState, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import getUrl from './LambdaUrls';
import lscache from 'lscache';
import './listView.css';
import { DesktopContainer } from './components/DesktopContainer';
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Auth } from 'aws-amplify';
import { BackButton } from './components/Buttons/BackButton';
import { FeedbackButton } from './components/Buttons/FeedbackButton';
import Scoreboard from './components/Scoreboard';
import Select from 'react-select';
import { useGeolocated } from 'react-geolocated';
import { DirectionsRenderer, GoogleMap, useJsApiLoader ,Marker} from '@react-google-maps/api';
import { getUserInfo } from './helper/profileAPIHelper';
/*global google*/

function isTimeBetween(currentTime, startTime, endTime) {
  if (startTime <= endTime) {
    return currentTime >= startTime && currentTime <= endTime;
  } else {
    return currentTime >= startTime || currentTime <= endTime;
  } 
}

function getCommonTagsCount(place, userInterests) {
  if (!place.tagname || !userInterests) {
    return 0;
  }

  const commonTagsCount = place.tagname.filter(tag => 
    tag && userInterests.some(interest => 
      interest.name && interest.name.toLowerCase() === tag.toLowerCase()
    )
  ).length;

  console.log(place, userInterests, commonTagsCount);

  return commonTagsCount;
}

function calculateDistance(lat1, lon1, lat2, lon2) {
  // Radius of the Earth in kilometers
  const radius = 6371;

  // Convert degrees to radians
  const lat1Rad = lat1 * Math.PI / 180;
  const lon1Rad = lon1 * Math.PI / 180;
  const lat2Rad = lat2 * Math.PI / 180;
  const lon2Rad = lon2 * Math.PI / 180;

  // Haversine formula
  const dLat = lat2Rad - lat1Rad;
  const dLon = lon2Rad - lon1Rad;
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1Rad) * Math.cos(lat2Rad) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in kilometers
  const distance = radius * c;

  return distance;
}

function getNearestCity(coords,citiesO) {
  if (!coords || citiesO.length == 0) return 'Canada';
  let minDistance=999999999999;
  let minCity='Canada';
  citiesO.map((c)=>{	 
     let minD=999999999999;
	 if (c.latitude!=0) minD=calculateDistance(coords.latitude,coords.longitude,c.latitude,c.longitude);
	 if (minD<minDistance) {
	   minDistance=minD;
	   minCity=c.cname;
	 }
  });
  console.log('distance',minCity,minDistance);
  if (minDistance<80) return minCity;
  else return 'Canada';
}


function convertTo24HourFormat(time) {
  const [hours, minutes, period] = time.split(/:| /);
  let hours24 = parseInt(hours, 10);
  if (period === 'PM' && hours24 < 12) {
    hours24 += 12;
  }

  if (period === 'AM' && hours24 === 12) {
    hours24 = 0;
  }

  return `${hours24.toString().padStart(2, '0')}:${minutes}`;
}

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function isOpen(place) {
 const now = new Date();
 const currentDay = days[now.getDay()];
 const currentHour = now.getHours();
 const currentMinute = now.getMinutes();

 const openingHours = place.opening_hours.find(hours => hours.day === currentDay);

 if (!openingHours) {
   return false;
 }

 const openHour = parseInt(openingHours.open.split(':')[0]);
 const openMinute = parseInt(openingHours.open.split(':')[1]);
 const closeHour = parseInt(openingHours.close.split(':')[0]);
 const closeMinute = parseInt(openingHours.close.split(':')[1]);

 const currentTime = currentHour * 60 + currentMinute;
 const openTime = openHour * 60 + openMinute;
 const closeTime = closeHour * 60 + closeMinute;

 return currentTime >= openTime && currentTime <= closeTime;
}

export default function ListView() {
  const [place, setPlace] = useState([])
  const [cities, setCities] = useState([])
  const [citiesO, setCitiesO] = useState([])
  const [city, setCity] = useState('')

  const [showmore, setShowmore] = useState(false)
  const [userInput, setUserInput] = useState('')

  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [directions, setDirections] = React.useState(null);
  const [admin, setAdmin] = useState(false);
  const [userInterests, setUserInterests] = useState([])
  const [searchInput, setSearchInput] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedTransportation, setSelectedTransportation] = useState("DRIVING")
  const options = [
    { 
      value: 'interests', 
      label: 'Take your interests into account' 
    },
    { 
      value: 'distance', 
      label: 'Distance',
      options: [
        { value: 'distance', label: '<1 KM' },
        { value: 'distance', label: '<5 KM' },
        { value: 'distance', label: '<10 KM' },
        { value: 'distance', label: '<20 KM' },
      ]
    },
    { 
      value: 'time', 
      label: 'Time',
      options: [
        { value: 'time', label: '<15 Minutes' },
        { value: 'time', label: '<30 Minutes' },
        { value: 'time', label: '<45 Minutes' },
        { value: 'time', label: '<1 Hour' },
      ]
    },
    // Add more options as needed
  ];
  const myNavigate = (lat, lng, lat1, lng1) => {
    let travelMode = localStorage.getItem('TRANSIT');
    if (!travelMode) { travelMode = google.maps.TravelMode.DRIVING; localStorage.setItem('TRANSIT', travelMode); }
    console.log('TRANSIT', travelMode);
    const service = new google.maps.DirectionsService();
    service.route(
      { destination: { lat: +lat, lng: +lng }, origin: { lat: +lat1, lng: +lng1 }, travelMode: travelMode },
      (result, status) => {
        console.log('route', status, result);
        if (status==='OK' && result) setDirections(result);
      }
    );
  }
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  console.log('geo:', isGeolocationAvailable);

  let navigate = useNavigate();
  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const allowedEmails = [
        'thydoraemon13@gmail.com',
        'ranmac3@hotmail.com',
        'goldrunnercode123@gmail.com',
        'hieuvpn@gmail.com'
      ];
      if (allowedEmails.includes(user.attributes.email)) {
        setAdmin(true);
      } else {
        setAdmin(false);
      }
    } catch (error) {
      setAdmin(false);
    }
  };
  const handleSelectChange = (selectedOption) => {
    setSelectedOptions(selectedOption);
  };
  const checkUserSignedIn = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsUserSignedIn(true);
    } catch (error) {
      setIsUserSignedIn(false);
    }
  };
  const getUserTransportation = async () =>{
    await getUserInfo().then((data) =>{
      const transportation = data['transportation'];
      switch (transportation) {
        case 'Drive':{
          setSelectedTransportation("DRIVING");
        }
        case 'Transit':{
          setSelectedTransportation("TRANSIT")
        }
        case 'Scooter': {
          setSelectedTransportation("BICYCLING")
        }
        case 'Walk': {
          setSelectedTransportation("WALKING")
        }
      }
    });
  }
  useEffect(() => {
    checkUserSignedIn();

  }, []);


//calgary edmonton Vancouver montreal toronto saskatoon missauga 

  useEffect(() => {
	let c=lscache.get('coords');
	console.log('getcoords',coords,c,citiesO.length);
    if (coords && c === null ) {
	  if (citiesO.length>0) lscache.set('coords',{'lat':coords.latitude,'lon':coords.longitude},1);

	  let ucity=lscache.get('city');	
	  console.log('ucity',ucity);
	  if (ucity != null) { setCity(ucity); console.log('ucity',ucity);}
	  else {
		    console.log('getNearestCity');
		    let nearestcity = getNearestCity(coords,citiesO);
			if (nearestcity!=='Canada') {
		      setCity(nearestcity); 
		      lscache.set('city',nearestcity,1); // refresh current city every 1min 
			}
		  }
	}
  }, [coords,citiesO]);


  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleAddLocationClick = async () => {
    try {
      navigate('/add-location');
    } catch (error) {
      // Handle error here
      console.error('Error:', error);
    }
  };


  // const deletePlace = (placeId) => {
  //   // send a DELETE request to the API to remove the place
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
  //   myHeaders.append("Content-Type", "text/plain");

  //   var raw = "{\r\n    \"PlaceID\" : " + placeId + "\r\n}";
  //   console.log(raw)

  //   var requestOptions = {
  //     method: 'DELETE',
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: 'follow'
  //   };

  //   fetch(getUrl("deletePlace"), requestOptions)
  //     .then(response => response.text())
  //     .then(result => console.log(result))
  //     .catch(error => console.log('error', error));
  // };

  useLayoutEffect(() => {
    checkUser();
    fetchEvents();
    return () => {
    };
  }, []);
  useEffect(() => {
    return () => {
    };
  }, [userInterests]);
  useEffect(() => {
    fetchData();
    return () => {
    };
  }, []);
  
  useEffect(() => {
    fetchEvents();
	fetchCities();
    return () => {
    };
  }, []);

  const fetchEvents = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    fetch(getUrl("getAllPlaces"), requestOptions)
      .then(response => response.text())
      .then(result => setPlace(JSON.parse(result)))
      .catch(error => console.log('error', error));
  };

  const fetchCities = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    fetch(getUrl("cities"), requestOptions)
      .then(response => response.text())
      .then(result => { let c=JSON.parse(result); 
			setCitiesO(c);
			console.log('Cities',c); 
			setCities(c.map(city=>{return(city.cname)})); } )
      .catch(error => console.log('error', error));
	let ucity=lscache.get('city');
	
	if (ucity != null) { setCity(ucity); console.log('ucity',ucity);}
  };


  const fetchData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));

    var raw = "{\r\n    \"PlaceID\": " + localStorage.getItem("place") + ", \r\n \"email\": \"" + String(localStorage.getItem('email')) + "\"\r\n}";

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        //body: raw,
        redirect: 'follow'
    };
    var savedInterests
    fetch(getUrl('getTags'), requestOptions)
        .then(response => response.text())
        .then(result => {
            const data = JSON.parse(result);
            console.log('DATA', data)
            const parsedData = data.map(item => ({
                ...item,
                checked: item.checked === 'true',
            }));
            console.log('PARSED', parsedData)
            const filteredData = parsedData.filter(item => {
              console.log(item.name, item.checked); // This will log the name and checked status of each item
              return item.checked === true;
            });
            savedInterests = filteredData
            setUserInterests(filteredData)
        })
        .catch(error => console.log('error', error));
        console.log(savedInterests)
        setUserInterests(savedInterests)

};
    const filteredPlaces = useMemo(() => {
	  
    if (window.location.pathname === '/PlaceToEat') { localStorage.setItem('search', 'food'); }
    if (window.location.pathname === '/FunThingsToDo') { localStorage.setItem('search', 'entertainment'); }
      let filtered = place.filter((place) => 
        place.ptype.toLowerCase() === localStorage.getItem('search')
      );
	  
	  if (city!='' && city != 'Canada' ) // Canada means no filter at all
	  {
		console.log('filter by city',city);
		let ltmp = filtered;
		filtered = ltmp.filter((place) => 
        place.city.toLowerCase() === city.toLowerCase()
      );
	  }
      return filtered.sort((a, b) => b.click_counter - a.click_counter).map((place) => ({
        ...place,
        commonTagsCount: getCommonTagsCount(place, userInterests),
      }));
    }, [place, searchInput]);

  const navigateToPlaceView = (placeName, placeid) => {
    localStorage.setItem('place', placeid);
	navigate(`/placeView/${placeName}/${placeid}`);
  };

  const getOrdinalSuffix = (num) => {
    const suffixes = ['st', 'nd', 'rd'];
    const suffixIndex = (num - 1) % 10 < 3 ? (num - 1) % 10 : 3;
    const ordinal = num + suffixes[suffixIndex];

    let backgroundColor;
    if (num === 1) {
      backgroundColor = '#7697CA'; // 1st place
    } else if (num === 2) {
      backgroundColor = '#F1C57E'; // 2nd place
    } else if (num === 3) {
      backgroundColor = '#E75A57'; // 3rd place
    }

    return (
      <div
        className="numbers"
        style={{
          position: 'absolute',
          top: '50%',
          left: '3%',
          transform: 'translateY(-50%)',
          borderRadius: '50px',
          backgroundColor: backgroundColor,
          textAlign: 'center',
          fontWeight: 'bold',
          zIndex: 1,
          paddingTop: '10px',
          color: 'white',

        }}
      >
        {ordinal}
      </div>
    );
  };

  const topThreePlaces = useMemo(() => {
    return filteredPlaces.slice(0, 3);
  }, [filteredPlaces]);

  const openPlaces = useMemo(() => {
    var filtered = filteredPlaces.filter((place) => 
        place.placename.toLowerCase().includes(searchInput.toLowerCase()) ||  
        place.tagname.some(tagname => tagname && tagname.toLowerCase().includes(searchInput.toLowerCase()))
      );
    const distanceOptions = [
      { value: '<1km', label: '<1 KM' },
      { value: '<5km', label: '<5 KM' },
      { value: '<10km', label: '<10 KM' },
      { value: '<20km', label: '<20 KM' },
    ]
    
    if(selectedOptions.some(option => distanceOptions.some(distanceOption => 
      option.label === distanceOption.label
    )) && selectedOptions != [] && isGeolocationAvailable){
      
      const selectedDistanceOption = selectedOptions.find(option => 
        distanceOptions.some(distanceOption => 
          option.label === distanceOption.label
        )
      );
      
      //filtered = filtered.filter((place) => calculateDistance(parseFloat(place.latitude), parseFloat(place.longitude), coords.latitude, coords.longitude) <= parseFloat(selectedDistanceOption.label.replace(' KM', '').replace('<', '')))
	  //default distance 50KM
	  filtered = filtered.filter((place) => calculateDistance(parseFloat(place.latitude), parseFloat(place.longitude), coords.latitude, coords.longitude) <= 50); 
    }
    const open = filtered.filter((place) => isOpen(place));
    return open.sort((a, b) => {
      // Apply a weight to commonTagsCount
      var weight = 0;
      if(selectedOptions.some(option => option.value === "interests")){
        weight = 10;
      }else{
        weight = 0;
      }
      const aScore = a.click_counter + a.commonTagsCount * weight;
      const bScore = b.click_counter + b.commonTagsCount * weight;
      return bScore - aScore;
    });
   }, [filteredPlaces, selectedOptions]);

  const closePlaces = useMemo(() => {
    var filtered = filteredPlaces.filter((place) => 
        place.placename.toLowerCase().includes(searchInput.toLowerCase()) ||  
        place.tagname.some(tagname => tagname && tagname.toLowerCase().includes(searchInput.toLowerCase()))
      );
    const distanceOptions = [
      { value: '<1km', label: '<1 KM' },
      { value: '<5km', label: '<5 KM' },
      { value: '<10km', label: '<10 KM' },
      { value: '<20km', label: '<20 KM' },
    ]
    
    if(selectedOptions.some(option => distanceOptions.some(distanceOption => 
      option.label === distanceOption.label
    )) && selectedOptions != [] && isGeolocationAvailable){
      
      const selectedDistanceOption = selectedOptions.find(option => 
        distanceOptions.some(distanceOption => 
          option.label === distanceOption.label
        )
      );
      
      //filtered = filtered.filter((place) => calculateDistance(parseFloat(place.latitude), parseFloat(place.longitude), coords.latitude, coords.longitude) <= parseFloat(selectedDistanceOption.label.replace(' KM', '').replace('<', '')))
      //default distance 50KM
	  filtered = filtered.filter((place) => calculateDistance(parseFloat(place.latitude), parseFloat(place.longitude), coords.latitude, coords.longitude) <= 50); 
    
	}
    const closed = filtered.filter((place) => !isOpen(place));
    return closed.sort((a, b) => {
      // Apply a weight to commonTagsCount
      const weight = 10;
      const aScore = a.click_counter + a.commonTagsCount * weight;
      const bScore = b.click_counter + b.commonTagsCount * weight;
      return bScore - aScore;
    });
  }, [filteredPlaces, selectedOptions]);

  const renderPlaces = (places) => {

    let ordinalCounter = 1;
    let topThreeDisplayed = [false, false, false];
    const voting = places.filter(place => place.approval_status)
    return voting.map((place) => {
      const isTopThreePlace = topThreePlaces.includes(place);

      if (isTopThreePlace) {
        const index = topThreePlaces.indexOf(place);
        if (!topThreeDisplayed[index]) {
          topThreeDisplayed[index] = true;
          ordinalCounter = index + 1;
        } else {
          isTopThreePlace = false; // Do not display the ordinal number for non-unique top three places
        }
      }
      return (
        <div
          key={place.placename}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            position: 'relative',
          }}
        >
          {isTopThreePlace && getOrdinalSuffix(ordinalCounter)}
          <ListItemButton
            sx={{
              backgroundColor: 'white',
              width: '90vw',
              height: '10vh',
              margin: '0 auto',
              borderRadius: '10px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              textAlign: 'center',
              color: 'black',
              fontSize: '1rem',
              fontWeight: 'bold',
              boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.4);',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
              },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              //call the API to add a click here with place.placeid
              navigateToPlaceView(place.placename, place.placeid);
            }}
          >
            <div className="placeInfo" style={{ display: 'flex', alignItems: 'center', marginLeft: "10vw" }}>
              <div className="placeName" style={{ fontSize: '20px', marginRight: '20px', color: '#5C5B5B' }}>{place.placename}</div>
              <div className="clickCount" style={{ fontSize: '18px', fontWeight: "300", color: '#5C5B5B' }}>
                {place.click_counter} visits
              </div>
              {/* <div className="open-close">{isOpen(place) ? 'Open' : 'Close'}</div> */}
            </div>

          </ListItemButton>
          {/* {
            admin && // If user is admin, render the delete button
            <button
              onClick={() => deletePlace(place.placeid)}
              className="deleteButton"
            >
              Delete
            </button>
          } */}
        </div>
      );
    });
  };

    const onSChange = (e) => {
    const suggestions = cities;
    const userInput = e.currentTarget.value;    
    setUserInput(userInput);
	setSearchInput(userInput);
  };
  const onSClick = (e) => {
      console.log('onSClick',e.target.childNodes[0].nodeValue);
      if (e.target.childNodes[0].nodeValue=='more') setShowmore(true);
      else if (e.target.childNodes[0].nodeValue=='less') setShowmore(false);
      else {
        setCity(e.target.childNodes[0].nodeValue);
        lscache.set('city',e.target.childNodes[0].nodeValue,5); // keep selected city for 5 min
        setUserInput('');  
		setSearchInput('');
      }
  };
  const suggestionsListComponent = (e,showmore) => {  
     let renderSuggestions = [];
     if (!userInput) return (<div></div>);
    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = cities.filter((suggestion) =>
      suggestion.toLowerCase().startsWith(userInput.toLowerCase())
    );
    if (filteredSuggestions.length > 3 && showmore) {
         renderSuggestions = filteredSuggestions
    } else renderSuggestions = filteredSuggestions.slice(0,3);
    return (<div style={{ display: "flex", "flex-wrap": "wrap", width: "95vw", 'margin-left':'4vw' }}>
		<div className="suggestion-active" style={{'background-color':'#d3a6e5'}}onClick={onSClick} >Canada</div>
        {renderSuggestions.map((suggestion, index) => {
              let className;
              className = "suggestion-active";
              return (
                <div className={className} key={suggestion} onClick={onSClick}>
                  {suggestion}
                </div>
              );
            })} 
          {filteredSuggestions.length>3 && !showmore ? 
              <div className="suggestion-active" style={{'background-color':'#f1c40f'}}onClick={onSClick} >more</div> 
              :
              filteredSuggestions.length>3 ? <div className="suggestion-active" style={{'background-color':'#f1c40f'}}onClick={onSClick} >less</div> :''
          }     
    
       </div>);
  };

  return (
    <div>
      <DesktopContainer />
      <div className="mobile-container" style={{ overflow: 'hidden' }}>
        <div className='top-group'>
          <BackButton onClick={() => navigate('/suggestion')} />
          <Scoreboard onClick={() => navigate('/redeem')} />
          <FeedbackButton onClick={()=> navigate('/newsfeed')}/>
          
        </div>

	<Fragment>
		<div style={{display:'flex', 'padding-left':'4vw', alignItems: 'center'}}>
        <input
          type="text"
          onChange={onSChange}          
          value={userInput}
		  style={{width: '90vw', padding:'10px','border-radius':'10px',display:'block'}}
		  placeholder=" 🔍 Search for business or City"
        />
		<div style={{
				right: '25vw',
				top: '1.4vh',
				display: 'flex',
				'background-color': '#9ff196',
				width: '23vw',
				height: '7vw',
				'border-radius': '8px',
				'justify-content': 'center',
				marginLeft: '-25vw',
			}}><p style={{'align-self':'center','font-size':'small'}}>{city}</p></div>
		</div>
        {suggestionsListComponent(userInput,showmore)}	    
      </Fragment>

        <div style={{display: 'none', justifyContent: 'space-between', alignItems: 'center'}}>
          <input type="text" value={searchInput} onChange={handleSearchInputChange} placeholder=" 🔍 Search for business or City" 
			style={{width: '90vw','margin-left':'3vw', padding:'10px','border-radius':'10px'}} />
          <div style={{display:'none'}}><Select
            options={options}
            onChange={handleSelectChange}
            isMulti
            stylea={{width:'25%'}}
          /></div>
        </div>
        <ol style={{ padding: '12px', margin: 0, marginTop: '3vh', display: 'flex', flexDirection: 'column' }}>
          <AccessTimeIcon className='open' style={{ backgroundColor: '#8DAD59', transform: 'scale(2)', borderRadius: '5px', marginBottom: '30px', color: 'white', marginLeft: '14px' }} />
          {renderPlaces(openPlaces)}

          <AccessTimeIcon className='close' style={{ backgroundColor: '#DB6E65', transform: 'scale(2)', borderRadius: '5px', marginBottom: '30px', marginTop: '20px', color: 'white', marginLeft: '14px' }} />
          {renderPlaces(closePlaces)}
          {isUserSignedIn ? (
            <ListItemButton
              sx={{
                width: '85vw',
                height: '7vh',
                margin: '10px auto',
                display: 'block',
                borderRadius: '50px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                fontSize: '1.4rem',
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: ' #879fcf',
                border: ' 3px solid white',
                boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6)',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handleAddLocationClick}
            >
              Add location +2000 points
            </ListItemButton>
          ) : (
            <ListItemButton
              sx={{
                width: '85vw',
                height: '7vh',
                margin: '10px auto',
                display: 'block',
                borderRadius: '50px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                fontSize: '1.4rem',
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: ' #879fcf',
                border: ' 3px solid white',
                boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6)',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => navigate('/signin')}
            >
              Add location +2000 points
            </ListItemButton>
          )}
        </ol>
      </div>
    </div>
  );
}
