import React from 'react';
import './EventPlannerHook.css';
import { useNavigate } from 'react-router-dom';

function EventPlannerHook() {
    let navigate = useNavigate();
    const handleStart = () => {
        navigate('/EventInfoPage')
    };

    return (
        <div className="event-planner-hook hook-page">
            <h1>Welcome to Your Adventure Night!</h1>
            <p>Start planning your perfect evening with just a few clicks.</p>
            <button onClick={handleStart}>Start Your Adventure</button>
        </div>
    );
}

export default EventPlannerHook;
