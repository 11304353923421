import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import CloseIcon from '@mui/icons-material/Close';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from "react";
import { useGeolocated } from "react-geolocated";
import { useNavigate,useParams } from "react-router-dom";
import getUrl from './LambdaUrls';
import { BackButton } from './components/Buttons/BackButton';
import { TimeButton } from "./components/Buttons/TimeButton";
import { DesktopContainer } from './components/DesktopContainer';
import Scoreboard from './components/Scoreboard';
import "./placeView.css";
import ReactGA from 'react-ga4';

function PlaceView() {

  const { placeID } = useParams();

  const [cplace, setPlace] = useState({
    placename: "",
    ptype: "",
    selectiontype: "",
    address: "",
    imagepath: [],
    description: "",
    suggestion: "",
    directionslink: "",
    fav_counter: "",
    approval_status: true,
  });
  // this helps avoid issues with undefined state

  const [score, setScore] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [isPlaceModalOpen, setIsPlaceModalOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [isCheckInOpen, setIsCheckInOpen] = useState(true);

  const [likes, setLikes] = useState(0);
  const [checkIn, setCheckIn] = useState(0);

  const [clocation, setLocation] = useState({ addr: "" });

  const [checkInStep, setCheckInStep] = useState('location-checkin');
  const initialstate = images
  //update time
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  //handle clicking btwn carousels 
  const handleNext = () => {
    let nextIndex = currentIndex + 1;
    if (nextIndex === images.length) {
      nextIndex = 0
    }
    setCurrentIndex(nextIndex);
  };

  //determine location
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  console.log('geo:', isGeolocationAvailable);

  //call
  useEffect(() => {
    fetchData();
    fetchLikes();
  }, []);

  const fetchData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");    
	var raw = JSON.stringify({ "PlaceID": placeID, "email": localStorage.getItem('email') });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(getUrl("postPlace"), requestOptions)
      .then(response => response.text())
      .then(result => {
        setPlace(JSON.parse(result));
        var tmp = JSON.parse(result);
        if (tmp.approval_status === false) { navigate(`/voting-placeview/${tmp.placename}/${tmp.placeid}`); }
        if (tmp.user_fav[0]) setIconColor('rgb(235, 102, 96)');
        setLikes(tmp.fav_counter);
        setCheckIn(tmp.checkin_counter);
        var imagez = [...JSON.parse(result).imagepath].reverse()
        imagez.push("https://placehold.co/150x150/FDF5EA/FDF5EA")
        setImages(imagez);
        console.log('CPLACE', cplace.user_fav)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");

	var raw = JSON.stringify({ "email": localStorage.getItem('email') });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    var Userscore = 0
    fetch(getUrl("postScore"), requestOptions)
      .then(response => response.text())
      .then(result => setScore(JSON.parse(result)[0][0]))
      .catch(error => console.log('error', error));
    checkUserSignedIn();
  }, []);

  const checkUserSignedIn = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsUserSignedIn(true);
      console.log("signed in")
    } catch (error) {
      setIsUserSignedIn(false);
      console.log("notsignedin")
    }
  };

  const handlePrev = () => {
    let previousIndex = currentIndex - 1;
    if (previousIndex < 0) {
      previousIndex = images.length - 1;
    }
    setCurrentIndex(previousIndex);
  };

  //navigation
  let navigate = useNavigate();

  const handleIconClick = () => {
    setShow(!show);
  };

  //uploading picture option 
  const handleImageUpload = (event) => {
    if (isUserSignedIn) {
      const file = event.target.files[0];
      console.log("File type:", file.type);
      const contentType = file.type;

      // Create a FormData object to hold the image file
      const formData = new FormData();
      formData.append('image', file, file.name);
      const randomNum = Math.floor(10000000 + Math.random() * 90000000);
      const datetime = new Date();
      const dateString = datetime.toJSON();
      // Make the fetch request to upload the image
      fetch(`${getUrl('s3upload')}${placeID}---500---${localStorage.getItem("email")}---${dateString}.jpg`, {
        method: 'PUT',
        headers: {
          'Content-Type': contentType,
        },
        body: file,
      })
        .then((res) => {
          console.log('RES', res);

          setUploadStatus("Thanks for adding a picture, it's in the process of being approved");

          setTimeout(() => {
            setUploadStatus(null);
          }, 60000);
        })
        .catch((err) => {
          console.log(err)
        });


      // Update the score using fetch
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain");
      myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: `{\r\n  \"PlaceID\": \"${placeID}\",\r\n  \"imagePath": \"/images/${placeID}---500---${localStorage.getItem('email')}---${dateString}.jpg\"\r\n}`,
        redirect: 'follow',
      };
      fetch(getUrl('updateScore'), requestOptions)
        .then(response => response.text())
        .then((result) => {
          console.log(result);
          // Refresh the page after both image upload and score update
          setScore(score + 500)
        })
        .catch(error => console.log('error', error));

      handleModalClose();
    } else {
      navigate('/signin'); // Navigate to /signin if the user is not signed in
    }
  };

  // const isLastImage = currentIndex === images.length - 1;
  const isLastImage = currentIndex === images.length - 1;
  const renderUploadButton = isLastImage && isUserSignedIn;

  //click count for directions
  function handleDirectionsClick() {
    // window.location.href = cplace.directionslink;
    navigate(`/map/${cplace.placename}/${cplace.placeid}`);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    var raw = JSON.stringify({ "PlaceID": cplace.placeid });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(getUrl("addClick"), requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }


  useEffect(() => {
    // get the current day of the week (0 for Sunday, 1 for Monday, and so on)
    const currentDayOfWeek = new Date().getDay();

    if (cplace.opening_hours && cplace.opening_hours.length > currentDayOfWeek) {
      const openingHours = cplace.opening_hours[currentDayOfWeek];
      console.log("WE WANT TO LOOK AT THIS", openingHours.open)
      setIsOpen(
        openingHours.open !== 'Closed' &&
        isTimeBetween(new Date(), timeStringToDate(openingHours.open), timeStringToDate(openingHours.close))
      );
      console.log('TEST TIME', openingHours.open)
    }
  }, [cplace]);

  function timeStringToDate(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  }
  // helper function to convert time to 24-hour format
  function convertTo24HourFormat(time) {
    const [hours, minutes, period] = time.split(/:| /);
    let hours24 = parseInt(hours, 10);
    console.log('HOURS24', hours24);

    if (period === 'PM' && hours24 < 12) {
      hours24 += 12;
    }

    if (period === 'AM' && hours24 === 12) {
      hours24 = 0;
    }

    return `${hours24.toString().padStart(2, '0')}:${minutes}`;
  }

  // helper function to check if current time is between start and end time
  function isTimeBetween(currentTime, startTime, endTime) {
    console.log('CURRENT TIME', currentTime)
    console.log('START TIME', startTime)
    console.log('END TIME', endTime)
    if (startTime <= endTime) {
      return currentTime >= startTime && currentTime <= endTime;
    } else {
      return currentTime >= startTime || currentTime <= endTime;
    }
  }

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const handleModalClose = () => {
    setIsPlaceModalOpen(false);
    setIsOverlayVisible(false); // hide the grey overlay when the modal is closed
  };

  function formatTime(time) {
    const [hours, minutes] = time.split(":");
    let formattedTime = `${parseInt(hours, 10) % 12}:${minutes}`;

    if (hours >= 12) {
      formattedTime += " PM";
    } else {
      formattedTime += " AM";
    }

    return formattedTime;
  }

  //handling favourites - users + total
  const [iconColor, setIconColor] = useState('#879fcf');

  const [isLiked, setIsLiked] = useState(false);

  const handleCheckInClick = () => {

    setCheckInStep('image-upload');

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");

    var raw = JSON.stringify({ "PlaceID": cplace.placeid, "email": localStorage.getItem('email') });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(getUrl("getCheckin"), requestOptions)
      .then(response => response.text())
      .then(result => { console.log(result); if (result.length < 100) setCheckIn(checkIn + 1); })
      .catch(error => console.log('error', error));
  };


  //when check-in button is click will trigger handlePreCheckInClick
  const handlePreCheckInClick = () => {
    setCheckInStep('location-checkin');
    setIsPlaceModalOpen(true);
    setIsOverlayVisible(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");

    //get user's location = long + lat
    var raw = JSON.stringify({ "PlaceID": cplace.placeid, "long": isGeolocationAvailable ? coords ? coords.longitude : '0' : '0', "lat": isGeolocationAvailable ? coords ? coords.latitude : '0' : '0' });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(getUrl("getCheckin"), requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result);
        var tmp = JSON.parse(result);
        setLocation(JSON.parse(result));
      })
      .catch(error => console.log('error', error));

  }

  const handleFavouriteClick = () => {
    console.log('call test');
	var raw = JSON.stringify({ "PlaceID": cplace.placeid });
    if (iconColor === '#879fcf') {
      console.log('CLICK')

      // add to fav list
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('accessToken'));
      fetch(getUrl('getFav'), {
        method: 'POST',
        headers: myHeaders,
        body: raw
      })
        .then(response => {
          if (response.ok) {
            setIconColor('rgb(235, 102, 96)');
            setLikes(likes + 1); // +1 like
            console.log('colour change', iconColor)
          }
        })
        .catch(error => console.error('error adding to fav', error));
    } else {
      // remove place from user's fav list
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem('accessToken'));

      fetch(getUrl('getFav'), {
        method: 'DELETE',
        headers: myHeaders,
        body: raw
      })
        .then(response => {
          if (response.ok) {
            setIconColor('#879fcf');
            setLikes(likes - 1); // -1
          }
        })
        .catch(error => console.error('error removing fav', error));
    }
  };


  const fetchLikes = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));

    fetch(getUrl('getFav'), {
      method: 'GET',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(data => {
        const userEmail = localStorage.getItem('email');
        const isUserLiked = data.some(item => item.email === userEmail);
        setIsLiked(isUserLiked);
      })
      .catch(error => console.error('error fetching likes', error));
  };


  return (
    <div>
      <DesktopContainer />
      <div className="page-container mobile-container">
        {cplace.approval_status ?
          (
            <div>
              {/* APPROVED */}
              {isOverlayVisible && <div className="overlaypage" />}
              {uploadStatus && <div style={{ paddingTop: '5px' }}>{uploadStatus}</div>}
              {/* the top where shows time and score */}
              <div className="top-group tg-placeview">
                {/* back to home*/}
                <BackButton onClick={() => navigate(cplace.ptype === 'food' ? '/PlaceToEat' : '/FunThingsToDo')} />
                <Scoreboard onClick={() => navigate('/redeem')} />
                {/* <AccessTimeIcon className={`icon ${isOpen ? 'green' : 'red'}`} onClick={handleIconClick} /> */}
                <TimeButton style={{ backgroundColor: isOpen ? 'rgb(141, 173, 89)' : 'rgb(219, 110, 101)' }} onClick={handleIconClick} />

              </div>

              {show && (
                <div className="time-table">
                  <div className="time-table-content">
                    <BackButton style={{ position: 'absolute', top: '40px', left: '30px' }} className='close-time-table' onClick={() => setShow(false)} />
                    <table className="date-time-table">
                      <thead>
                        <tr className="date-time-tr">
                          <th>Day</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody className="date-time-tbody">
                        {cplace.opening_hours.map((dayHours) => (
                          <tr key={dayHours.day}>
                            <td>{dayHours.day}</td>
                            <td>
                              {dayHours.open === "" || dayHours.close === ""
                                ? "Closed"
                                : `${formatTime(dayHours.open)} - ${formatTime(dayHours.close)}`}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}


              {/* place info */}


              <h1 className={`h1-tag${cplace.placename.length > 15 ? ' height-adjust' : ''}`} >{cplace.placename}</h1>
              <h2 className="h2-types">{cplace.selectiontype.charAt(0).toUpperCase() + cplace.selectiontype.slice(1)}</h2>
              <p className="add-picture-note">Add <br></br>picture</p>
              <div className="image-container">
              
                {images.map((imageUrl, index) => (
                  <img
                    key={index}
                    src={imageUrl.charAt(0) === '/' ? getUrl('s3imagePath') + imageUrl : imageUrl}
                    alt="Gallery"
                    className="image-con"
                    style={{
                      display: imageUrl === images[currentIndex] ? "block" : "none",
                      objectFit: "cover",
                      borderRadius: "15px",
                      border: '5px solid white',
                      boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6)',
                      marginBottom: '10px'
                    }}
                  />
                ))}


                {renderUploadButton ? (
                  <>
                    <label htmlFor="fileInput">
                      <button
                        style={{
                          height: "30vh",
                          width: '65vw',
                          cursor: "pointer",
                          fontSize: '16px',
                          border: 'none',
                          borderRadius: '10px',
                          // boxShadow: '0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%',
                          zIndex: 10,
                          // backgroundColor: 'rgb(135, 159, 207)',
                          backgroundColor: "#fdf5ea",
                          color: 'rgb(135, 159, 207)',
                          display: 'flex',
                          flexDirection: 'column',
                          alignContent: 'center',
                          alignItems: 'center',
                          paddingTop: '5vh'
                        }}

                        onClick={() => { document.getElementById('fileInput').click() }}
                      >
                        <img src="/assets/picture.png" alt="picture-icon" style={{ width: '18vw' }} />
                        <h3 style={{ fontSize: '5.5vw' }}>Add Pictures <br /> 500 Points</h3>
                      </button>
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={handleImageUpload}
                    />
                  </>
                ) : (
                  <>
                    {isLastImage && (
                      <label htmlFor="fileInput">
                          <button
                            style={{
                              height: "30vh",
                              width: '65vw',
                              cursor: "pointer",
                              fontSize: '16px',
                              border: 'none',
                              borderRadius: '10px',
                              // boxShadow: '0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%',
                              zIndex: 10,
                              // backgroundColor: 'rgb(135, 159, 207)',
                              backgroundColor: "#fdf5ea",
                              color: 'rgb(135, 159, 207)',
                              display: 'flex',
                              flexDirection: 'column',
                              alignContent: 'center',
                              alignItems: 'center',
                              paddingTop: '5vh'
                            }}

                            onClick={() => navigate('/signin')}
                          >
                            <img src="/assets/picture.png" alt="picture-icon" style={{ width: '18vw' }} />
                            <h3 style={{ fontSize: '5.5vw' }}>Add Pictures <br /> 500 Points</h3>
                          </button>
                      </label>)}
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                    />
                  </>
                )}
              </div>

              {/* handle clicking for pictures */}

              <button onClick={(handlePrev)} className="icons left" >
                <ChevronLeft style={{ transform: 'scale(2, 2.5)' }} />
              </button>
              <button onClick={handleNext} className="icons right" >
                <ChevronRight style={{ transform: 'scale(2, 2.5)' }} />
              </button>


              <div className="dots">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.5rem", alignContent: 'center' }}>
                  {images.map((slide, image) => (
                    <div className="image-dots"
                      style={{
                        transition: "all 0.2s",
                        width: "0.75rem",
                        height: "0.75rem",
                        backgroundColor: currentIndex === image ? "white" : "#77320A",
                        borderRadius: "50%",
                        padding: currentIndex === image ? "0.25rem" : "0",
                        opacity: currentIndex === image ? "1" : "0.5",
                        cursor: 'pointer',
                        marginBottom: '2vh',
                        boxShadow: currentIndex === image ? "0px 3px 3px 1px rgba(198, 126, 40, 0.6)" : "none"
                      }}
                      onClick={() => setCurrentIndex(image)}
                    />
                  ))}

                </div>
              </div>

              <div className="icons-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '85%', marginLeft: '6vw', marginTop: '-3vh' }}>
                <div
                  className={`locationicon ${(!coords || !coords.latitude || !coords.longitude) ? 'disabled-placeview' : ''}`}
                  style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#EB6660',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '15px',
                    boxShadow: '0px 6px 4px rgba(186, 128, 33, 0.4), 0px -5px 0px rgba(0, 0, 0, 0.2) inset',
                    borderRadius: '10px',
                    cursor: 'pointer',

                  }}
                  onClick={handlePreCheckInClick}

                >
                  <FmdGoodIcon style={{ transform: 'scale(1.4)', color: 'white', }} />

                </div><div style={{ width: '45vw', display: 'flex', 'justify-content': 'space-around', 'flex-direction': 'column-reverse', }} >{checkIn}</div>

                <div
                  className="favourite-icon"
                  style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fdf5ea',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '15px',
                    // boxShadow: '0px 6px 4px rgba(186, 128, 33, 0.4), 0px -5px 0px rgba(0, 0, 0, 0.2) inset',
                    borderRadius: '10px',
                    cursor: 'pointer',

                  }}
                >
                  <FavoriteOutlinedIcon
                    style={{
                      transform: 'scale(2)',
                      color: iconColor,
                      cursor: 'pointer',
                    }}
                    onClick={handleFavouriteClick}
                  />
                  <div className="like-count" style={{ marginLeft: '5vw' }}>{likes}</div>

                </div>
              </div>

              {/* message to make sure user's location is determined */}
              {/* {isGeolocationAvailable ? coords ? coords.latitude : '0' : '0'}  {isGeolocationAvailable ? coords ? coords.longitude : '0' : '0'} */}
              {(!coords || !coords.latitude || !coords.longitude) && (
                <p> Turn on location to check-in</p>
              )}
              <div>


              </div>

              <div className="description-div"><p className="description">{cplace.pdescription}</p></div>


              <div className="suggestions" style={{ height: '10px', display: 'flex', alignItems: 'center', marginLeft: '10px', fontSize: '1.1rem', fontWeight: '300' }}>
                <img src="/assets/logo2.png" alt="logo" style={{ width: '10vw', height: '6vh' }} />
                {isUserSignedIn ? (<p style={{ marginLeft: '10px', marginTop: '15px' }}>{cplace.suggestion === "" ? "No suggestion yet" : cplace.suggestion}</p>) : <p style={{ marginLeft: '10px', marginTop: '15px' }}><span style={{ textDecoration: 'underline', color: '#81A0D3' }} onClick={() => navigate('/signin')}>Sign In</span> to see exclusive offers</p>}
              </div>
              <button className="directions" onClick={handleDirectionsClick} style={{ fontSize: '20px', fontWeight: 'bold' }}>
                Directions
              </button>
            </div>
          )
          :

          (<div>
            {/* <div className="top-group tg-placeview">
              <BackButton onClick={() => navigate("/voting")} />
              <div></div>
              <div></div>
            </div> */}
            <img onClick={() => navigate('/voting')} src="https://cluez.ca/images/return.png" alt="img" className="pending-image" />
            <div className="pending-style">
              {/* PENDING */}
              {isOverlayVisible && <div className="overlaypage" />}
              {uploadStatus && <div style={{ paddingTop: '5px' }}>{uploadStatus}</div>}
              {/* the top where shows time and score */}
              <div className="top-group tg-placeview">
                {/* back to home*/}
                <BackButton onClick={() => navigate("/listview")} />
                <Scoreboard onClick={() => navigate('/redeem')} />
                {/* <AccessTimeIcon className={`icon ${isOpen ? 'green' : 'red'}`} onClick={handleIconClick} /> */}
                <TimeButton style={{ backgroundColor: isOpen ? 'rgb(141, 173, 89)' : 'rgb(219, 110, 101)' }} onClick={handleIconClick} />

              </div>

              {show && (
                <div className="time-table">
                  <div className="time-table-content">
                    <BackButton style={{ position: 'absolute', top: '40px', left: '30px' }} className='close-time-table' onClick={() => setShow(false)} />
                    <table className="date-time-table">
                      <thead>
                        <tr className="date-time-tr">
                          <th>Day</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody className="date-time-tbody">
                        {cplace.opening_hours.map((dayHours) => (
                          <tr key={dayHours.day}>
                            <td>{dayHours.day}</td>
                            <td>
                              {dayHours.open === "" || dayHours.close === ""
                                ? "Closed"
                                : `${formatTime(dayHours.open)} - ${formatTime(dayHours.close)}`}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}


              {/* place info */}

              <h1 className={`h1-tag${cplace.placename.length > 15 ? ' height-adjust' : ''}`} >{cplace.placename}</h1>
              <h2 className="h2-types">{cplace.selectiontype.charAt(0).toUpperCase() + cplace.selectiontype.slice(1)}</h2>

              <div className="image-container">
                {images.map((imageUrl, index) => (
                  <img
                    key={index}
                    src={imageUrl.charAt(0) === '/' ? getUrl('s3imagePath') + imageUrl : imageUrl}
                    alt="Gallery"
                    className="image-con"
                    style={{
                      display: imageUrl === images[currentIndex] ? "block" : "none",
                      objectFit: "cover",
                      borderRadius: "15px",
                      border: '5px solid white',
                      boxShadow: '0px 4px 3px 1px rgba(220, 154, 87, 0.6)',
                      marginBottom: '10px'
                    }}
                  />
                ))}


                {renderUploadButton ? (
                  <>
                    <label htmlFor="fileInput">
                      <button
                        style={{
                          height: "30vh",
                          width: '65vw',
                          cursor: "pointer",
                          fontSize: '16px',
                          border: 'none',
                          borderRadius: '10px',
                          // boxShadow: '0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%',
                          zIndex: 10,
                          // backgroundColor: 'rgb(135, 159, 207)',
                          backgroundColor: "#fdf5ea",
                          color: 'rgb(135, 159, 207)',
                          display: 'flex',
                          flexDirection: 'column',
                          alignContent: 'center',
                          alignItems: 'center',
                          paddingTop: '5vh'
                        }}

                        onClick={() => { document.getElementById('fileInput').click() }}
                      >
                        <img src="/assets/picture.png" alt="picture-icon" style={{ width: '18vw' }} />
                        <h3 style={{ fontSize: '5.5vw' }}>Add Pictures <br /> 500 Points</h3>
                      </button>
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={handleImageUpload}
                    />
                  </>
                ) : (
                  <>
                    {isLastImage && (
                      <label htmlFor="fileInput">
                        <button
                          style={{
                            height: "9vh",
                            width: '50vw',
                            cursor: "pointer",
                            fontSize: '14px',
                            border: '3px solid white',
                            borderRadius: '10px',
                            boxShadow: '0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%',
                            zIndex: 10,
                            color: 'white',
                            backgroundColor: '#879fcf',
                            fontSize: '16px'
                          }}

                          onClick={() => { navigate("/signin") }}
                        >
                          <span style={{ textDecoration: 'underline', color: 'white' }}>Sign In</span> to upload images
                        </button>
                      </label>)}
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                    />
                  </>
                )}
              </div>

              {/* handle clicking for pictures */}

              <button onClick={(handlePrev)} className="icons left" >
                <ChevronLeft style={{ transform: 'scale(2, 2.5)' }} />
              </button>
              <button onClick={handleNext} className="icons right" >
                <ChevronRight style={{ transform: 'scale(2, 2.5)' }} />
              </button>


              <div className="dots">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.5rem", alignContent: 'center' }}>
                  {images.map((slide, image) => (
                    <div className="image-dots"
                      style={{
                        transition: "all 0.2s",
                        width: "0.75rem",
                        height: "0.75rem",
                        backgroundColor: currentIndex === image ? "white" : "#77320A",
                        borderRadius: "50%",
                        padding: currentIndex === image ? "0.25rem" : "0",
                        opacity: currentIndex === image ? "1" : "0.5",
                        cursor: 'pointer',
                        marginBottom: '2vh',
                        boxShadow: currentIndex === image ? "0px 3px 3px 1px rgba(198, 126, 40, 0.6)" : "none"
                      }}
                      onClick={() => setCurrentIndex(image)}
                    />
                  ))}

                </div>
              </div>

              <div className="icons-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '85%', marginLeft: '6vw', marginTop: '-3vh' }}>
                <div
                  className={`locationicon ${(!coords || !coords.latitude || !coords.longitude) ? 'disabled-placeview' : ''}`}
                  style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#EB6660',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '15px',
                    boxShadow: '0px 6px 4px rgba(186, 128, 33, 0.4), 0px -5px 0px rgba(0, 0, 0, 0.2) inset',
                    borderRadius: '10px',
                    cursor: 'pointer',

                  }}
                  onClick={handlePreCheckInClick}

                >
                  <FmdGoodIcon style={{ transform: 'scale(1.4)', color: 'white', }} />

                </div><div style={{ width: '45vw', display: 'flex', 'justify-content': 'space-around', 'flex-direction': 'column-reverse', }} >{checkIn}</div>

                <div
                  className="favourite-icon"
                  style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fdf5ea',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '15px',
                    // boxShadow: '0px 6px 4px rgba(186, 128, 33, 0.4), 0px -5px 0px rgba(0, 0, 0, 0.2) inset',
                    borderRadius: '10px',
                    cursor: 'pointer',

                  }}
                >
                  <FavoriteOutlinedIcon
                    style={{
                      transform: 'scale(2)',
                      color: iconColor,
                      cursor: 'pointer',
                    }}
                    onClick={handleFavouriteClick}
                  />
                  <div className="like-count" style={{ marginLeft: '5vw' }}>{likes}</div>

                </div>
              </div>

              {/* message to make sure user's location is determined */}
              {/* {isGeolocationAvailable ? coords ? coords.latitude : '0' : '0'}  {isGeolocationAvailable ? coords ? coords.longitude : '0' : '0'} */}
              {(!coords || !coords.latitude || !coords.longitude) && (
                <p> Turn on location to check-in</p>
              )}
              <div>


              </div>

              <div className="description-div"><p className="description">{cplace.pdescription}</p></div>


              <div className="suggestions" style={{ height: '10px', display: 'flex', alignItems: 'center', marginLeft: '10px', fontSize: '1.1rem', fontWeight: '300' }}>
                <img src="/assets/logo2.png" alt="logo" style={{ width: '10vw', height: '6vh' }} />
                {isUserSignedIn ? (<p style={{ marginLeft: '10px', marginTop: '15px' }}>{cplace.suggestion === "" ? "No suggestion yet" : cplace.suggestion}</p>) : <p style={{ marginLeft: '10px', marginTop: '15px' }}><span style={{ textDecoration: 'underline', color: '#81A0D3' }} onClick={() => navigate('/signin')}>Sign In</span> to see exclusive offers</p>}
              </div>
              <button className="directions" onClick={handleDirectionsClick} style={{ fontSize: '20px', fontWeight: 'bold' }}>
                Directions
              </button>
            </div> </div>)}


      </div>
      {/* checkin popup */}
      {
        isPlaceModalOpen && isCheckInOpen && isOverlayVisible && (
          <div className="checkin">
            {checkInStep === 'location-checkin' ? (
              <div className="checkin-content location-checkin">
                <div className="checkin-container">
                  <div className="close-icon">
                    <CloseIcon onClick={handleModalClose} style={{ color: 'white' }} />
                  </div>
                  <p className="location-check"> Are you at {cplace.placename}?</p>
                  {clocation.distance > 500 ? (
                    <h2 className="checkin-points">Get closer to checkin for 1000 points</h2>
                  ) : (
                    <h2 className="checkin-points">Check in for 1000 points!</h2>
                  )}
                  <button
                    className="checkin-btn-PV"
                    onClick={handleCheckInClick}
                    style={{
                      opacity: clocation.distance > 500 ? 0.5 : 1,
                      pointerEvents: clocation.distance > 500 ? 'none' : 'auto',
                    }}
                  >
                    Check In
                  </button>

                  <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', cursor: 'pointer' }}
                    className="skipdiv"
                    onClick={handleModalClose}
                  >
                    Skip
                    <KeyboardDoubleArrowRightIcon style={{ transform: 'scale(1.2)' }} />
                  </div>
                </div>
              </div>

            ) : (
              <div className="checkin-container image-upload">
                <div className="close-icon">
                  <CloseIcon onClick={handleModalClose} style={{ color: 'white' }} />
                </div>
                <div>
                  <label htmlFor="fileInput">
                    <button
                      style={{
                        height: "30%",
                        width: '65%',
                        cursor: "pointer",
                        fontSize: '16px',
                        border: 'none',
                        borderRadius: '10px',
                        position: 'absolute',
                        top: '20%',
                        left: '50%',
                        transform: 'translate(-50%, -50%',
                        zIndex: 10,
                        backgroundColor: "rgba(253, 245, 234, 0)",
                        color: 'black',
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        alignItems: 'center',
                        paddingTop: '5vh'
                      }}

                      onClick={() => { document.getElementById('fileInput').click() }}
                    >
                      <img src="/assets/picture.png" alt="picture-icon" style={{ width: '18vw' }} />
                      <div style={{ fontSize: '5.5vw', marginTop: '1vh' }}>Add Pictures <br /><div className="s-500-points"> 500 Points</div></div>
                    </button>
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                  />
                  <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', fontSize: '18px', cursor: 'pointer' }}
                    className="skipdiv"
                    onClick={handleModalClose}
                  >
                    Skip
                    <KeyboardDoubleArrowRightIcon style={{ transform: 'scale(1.2)' }} />
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      }
    </div >
  );
}

export default PlaceView; 
