import { TextareaAutosize } from '@mui/material';
import { Select, MenuItem, InputLabel, FormControl, Button } from '@mui/material';

import React, { useState, useEffect , Fragment} from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { BackButton } from './components/Buttons/BackButton';
import { DesktopContainer } from './components/DesktopContainer';
import Stack from '@mui/material/Stack';
import './EventInfoPage.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs from 'dayjs';
import getUrl from './LambdaUrls';
import lscache from 'lscache';
import { useGeolocated } from 'react-geolocated';
import AutoCompleteInput from './components/AutoCompleteInput';
function calculateDistance(lat1, lon1, lat2, lon2) {
    // Radius of the Earth in kilometers
    const radius = 6371;
  
    // Convert degrees to radians
    const lat1Rad = lat1 * Math.PI / 180;
    const lon1Rad = lon1 * Math.PI / 180;
    const lat2Rad = lat2 * Math.PI / 180;
    const lon2Rad = lon2 * Math.PI / 180;
  
    // Haversine formula
    const dLat = lat2Rad - lat1Rad;
    const dLon = lon2Rad - lon1Rad;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(lat1Rad) * Math.cos(lat2Rad) *
              Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    // Distance in kilometers
    const distance = radius * c;
  
    return distance;
  }

function getNearestCity(coords,citiesO) {
    if (!coords || citiesO.length == 0) return 'Canada';
    let minDistance=999999999999;
    let minCity='Canada';
    citiesO.map((c)=>{	 
       let minD=999999999999;
       if (c.latitude!=0) minD=calculateDistance(coords.latitude,coords.longitude,c.latitude,c.longitude);
       if (minD<minDistance) {
         minDistance=minD;
         minCity=c.cname;
       }
    });
    console.log('distance',minCity,minDistance);
    if (minDistance<80) return minCity;
    else return 'Canada';
  }

const EventInfoPage = () => {
    
    const navigate = useNavigate(); // Hook for programmatic navigation
    const [page, setPage] = useState('Page 1'); // State to track the current page
    const [eventName, setEventName] = useState('');
    const [eventCity, setEventCity] = useState('');
    const [eventAddress, setEventAddress] = useState('');
    const [eventDate, setEventDate] = useState(dayjs());
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const [startTime, setStartTime] = useState(dayjs());
    const [endTime, setEndTime] = useState(dayjs());
    const [price, setPrice] = useState('');
    const [ticketURL, setTicketURL] = useState('');
    const [eventPlace, setEventPlace] = useState('');

    const [userInput, setUserInput] = useState('')
    const [currentDateTime, setCurrentDateTime] = useState(new Date());


	const [cities, setCities] = useState([])
	const [citiesO, setCitiesO] = useState([])
	const [city, setCity] = useState('')

	const [showmore, setShowmore] = useState(false)
	const [showOtherInput, setShowOtherInput] = useState(false);
    const [otherInputValue, setOtherInputValue] = useState('');

 const handleChange = (event) => {
    setType(event.target.value);
    if (event.target.value === 'other') {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
    }
 };

 const handleOtherInputChange = (event) => {
    setOtherInputValue(event.target.value);
    setType(event.target.value);
 };
    useEffect(() => {
		fetchCities();
        // update current date and time every second
        const timer = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 2000000000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const fetchCities = () => {
		var requestOptions = {
		  method: 'GET',
		  redirect: 'follow'
		};
		fetch(getUrl("cities"), requestOptions)
		  .then(response => response.text())
		  .then(result => { let c=JSON.parse(result);  
				setCitiesO(c);
				setCities(c.map(city=>{return(city.cname)}));  
				} )
		  .catch(error => console.log('error', error));
		let ucity=lscache.get('city');
		
		if (ucity != null) { setCity(ucity); console.log('ucity',ucity);}
	  };

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  useEffect(() => {
	let c=lscache.get('coords');
	console.log('getcoords',coords,c,citiesO.length);
    if (coords && c === null ) {
	  if (citiesO.length>0) lscache.set('coords',{'lat':coords.latitude,'lon':coords.longitude},1);

	  let ucity=lscache.get('city');	
	  console.log('ucity',ucity);
	  if (ucity != null) { setCity(ucity); console.log('ucity',ucity);}
	  else {
		    console.log('getNearestCity');
		    let nearestcity = getNearestCity(coords,citiesO);
			if (nearestcity!=='Canada') {
		      setCity(nearestcity); 
		      lscache.set('city',nearestcity,1); // refresh current city every 1min 
			}
		  }
	}
  }, [coords,citiesO]);

    const suggestionsListComponent = (e,showmore) => {  
        let renderSuggestions = [];
        if (!userInput) return (<div></div>);
       // Filter our suggestions that don't contain the user's input
       const filteredSuggestions = cities.filter((suggestion) =>
         suggestion.toLowerCase().startsWith(userInput.toLowerCase())
       );
       if (filteredSuggestions.length==1) { //auto complete
           setCity(filteredSuggestions[0]);
           lscache.set('city',filteredSuggestions[0],5); // keep selected city for 5 min
           setUserInput('');  
           return (<div></div>);
       }
       if (filteredSuggestions.length > 3 && showmore) {
            renderSuggestions = filteredSuggestions
       } else renderSuggestions = filteredSuggestions.slice(0,3);
       return (<div style={{ display: "flex", "flex-wrap": "wrap", width: "95vw", 'margin-left':'4vw' }}>
           <div className="suggestion-active" style={{'background-color':'#d3a6e5'}}onClick={onSClick} >Canada</div>
           {renderSuggestions.map((suggestion, index) => {
                 let className;
                 className = "suggestion-active";
                 return (
                   <div className={className} key={suggestion} onClick={onSClick}>
                     {suggestion}
                   </div>
                 );
               })} 
             {filteredSuggestions.length>3 && !showmore ? 
                 <div className="suggestion-active" style={{'background-color':'#f1c40f'}}onClick={onSClick} >more</div> 
                 :
                 filteredSuggestions.length>3 ? <div className="suggestion-active" style={{'background-color':'#f1c40f'}}onClick={onSClick} >less</div> :''
             }     
       
          </div>);
     };


    const onSChange = (e) => {
        const userInput = e.currentTarget.value;    
        setUserInput(userInput);
    
      };
      const onSClick = (e) => {
        console.log('onSClick',e.target.childNodes[0].nodeValue);
        if (e.target.childNodes[0].nodeValue=='more') setShowmore(true);
        else if (e.target.childNodes[0].nodeValue=='less') setShowmore(false);
        else {
          setEventCity(e.target.childNodes[0].nodeValue);
          setCity(e.target.childNodes[0].nodeValue);
          setUserInput('');  
        }
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
  
      switch (page) {
          case 'Page 1':
              setPage('Page 2');
              break;
          case 'Page 2':
              setPage('Page 3');
              break;
          case 'Page 3':
              setPage('Page 4');
              break;
          case 'Page 4':
              console.log(eventName, type, description, eventCity, eventDate, startTime, endTime, price, ticketURL);
              
              const priceValue = parseFloat(price); // Parse the price to a float to handle calculations
              const priceType = priceValue === 0 ? 'Free' : 'Paid'; // Determine price type based on the value
              
              const eventData = {
                  event_name: eventName,
                  event_type: type,
                  event_date: eventDate.format('YYYY-MM-DD'),
                  event_start_time: startTime.format('HH:mm:ss'),
                  event_end_time: endTime.format('HH:mm:ss'),
                  event_details: description,
                  place: eventPlace,
                  addresses: eventAddress,
                  ticket_website: ticketURL,
                  price_type: priceType,
                  price: priceValue
              };
  
              const apiUrl = getUrl('events');
  
              try {
                  const response = await fetch(apiUrl, {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json',
                          'X-Amz-Date': new Date().toISOString(),
                          'Authorization': 'YourAuthToken', // Adjust as necessary
                          'X-Api-Key': 'YourApiKey', // Adjust as necessary
                      },
                      body: JSON.stringify(eventData)
                  });
                  
                  const responseData = await response.json();
                  console.log('Response:', responseData);
  
                  if (response.ok) {
                    setPage('Page 5');
                    setTimeout(() => {
                        navigate('/events')
                    }, 10000);
                  } else {
                      throw new Error('Failed to create event');
                  }
              } catch (error) {
                  console.error('Error submitting event:', error);
              }
              break;
      }
  };
  

    function renderSwitch(param) {
        switch (param) {
            case 'Page 1':
                return (
                    <div>
                        <DesktopContainer />
                        <div className="mobile-container" style={{ overflow: 'hidden' }}>
                            <BackButton
                                onClick={() => navigate('/eventplannerhook')}
                                style={{ marginTop: '4vh', marginLeft: '3vw' }}
                            />
                            <Stack
                                direction="column"
                                spacing={0}
                                justifyContent="center"
                                alignItems="center"
                                flexWrap="wrap"
                            >
                                <label className="formLabel-USERS">Select an experience</label>
                            </Stack>
                            
                            <form className="formContainer-USERS" onSubmit={handleSubmit}>
                                <div className="formGroup-USERS">
                                    <label htmlFor="eventName-USERS" className="formBold-USERS">
                                        How many people?
                                    </label>
                                    <Stack
                                direction="row"
                                spacing={1.5}
                                justifyContent="center"
                                alignItems="center"
                                flexWrap="wrap"
                            >
                                <Button className="formButtonUsers">1</Button>
                                <Button className="formButtonUsers">2</Button>
                                <Button className="formButtonUsers">3</Button>
                                <Button className="formButtonUsers">4</Button>
                            </Stack>
                                </div>
                                <div className="formGroup-USERS">
                                <label htmlFor="eventName-USERS" className="formBold-USERS">
                                        Experiences available
                                    </label>
                                    <FormControl variant="outlined" className="formGroup-USERS" fullWidth required>
                                    <InputLabel id="eventType-USERS-label"></InputLabel>
                                    <Select
                                        labelId="eventType-USERS-label"
                                        id="eventType-USERS"
                                        value={type}
                                        onChange={handleChange}
                                        className="formInput-USERS" // Apply your custom CSS class here
                                        
                                    >
                                        <MenuItem  value="Art expos">Art expos</MenuItem>
                                        <MenuItem value="Club">Club</MenuItem>
                                        <MenuItem value="Food festival">Food festival</MenuItem>
                                        <MenuItem value="House party">House party</MenuItem>
                                        <MenuItem value="Meetup">Meetup</MenuItem>
                                        <MenuItem value="Music festival">Music festival</MenuItem>
                                        <MenuItem value="Quest">Quest</MenuItem>
                                        <MenuItem value="Tournament">Tournament</MenuItem>
                                        <MenuItem value="Workshop">Workshop</MenuItem>
                                        <MenuItem value="other">Other</MenuItem>
                                    </Select>
                                    {showOtherInput && (
                                        <TextField
                                        label="Other Category"
                                        value={otherInputValue}
                                        onChange={handleOtherInputChange}
                                        fullWidth
                                        className="formInput-USERS" // Apply your custom CSS class here
                                        sx= {{marginTop : "2vh"}}
                                        />
                                    )}
                                    </FormControl>
    
                                </div>
                                <div className="formGroup-USERS">
                                    <label htmlFor="description-USERS" className="formLabel-USERS">
                                        Description:
                                    </label>
                                    <TextareaAutosize
                                        id="description-USERS"
                                        rows="10"
                                        className="formTextArea-USERS"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        style={{ height: '12vh', width: '82vw' }}
                                        required
                                    />
                                </div>
                                <button type="submit" className="submitButton-USERS">
                                1/4 Next 
                                </button>
                            </form>
                        </div>
                    </div>
                );
            case 'Page 2':
                return (
                    <div>
                        <DesktopContainer />
                        <div className="mobile-container" style={{ overflow: 'hidden' }}>
                            <BackButton
                                onClick={() => navigate('/events')}
                                style={{ marginTop: '4vh', marginLeft: '3vw' }}
                            />
                            <Stack
                                direction="column"
                                spacing={0}
                                justifyContent="center"
                                alignItems="center"
                                flexWrap="wrap"
                            >
                                <label className="formLabel-USERS">How many places do you</label>
                                <label className="formLabel-USERS">want to visit?</label>
                            </Stack>
                            
                            <form className="formContainer-USERS" onSubmit={handleSubmit}>
                                <div className="formGroup-USERS">
                                    <label htmlFor="eventName-USERS" className="formBold-USERS">
                                        Number of places
                                    </label>
                                    <Stack
                                direction="row"
                                spacing={1.5}
                                justifyContent="center"
                                alignItems="center"
                                flexWrap="wrap"
                            >
                                <Button className="formButtonUsers">1</Button>
                                <Button className="formButtonUsers">2</Button>
                                <Button className="formButtonUsers">3</Button>
                                <Button className="formButtonUsers">4</Button>
                            </Stack>
                                </div>
                                
                                <div className="formGroup-USERS">
                                    <label htmlFor="description-USERS" className="formBold-USERS">
                                        What's Included - 2:
                                    </label>
                                    
                                </div>
                                <div className="formGroup-USERS">
                                    <label htmlFor="description-USERS" className="formBold-USERS">
                                        Restraunt Activity
                                    </label>
                                    
                                </div>
                                <button type="submit" className="submitButton-USERS">
                                    2/4 Next
                                </button>
                            </form>
                        </div>
                    </div>
                );
            case 'Page 3':
                return (
                    <div>
                        <DesktopContainer />
                        <div className="mobile-container" style={{ overflow: 'hidden' }}>
                            <BackButton
                                onClick={() => navigate('/events')}
                                style={{ marginTop: '4vh', marginLeft: '3vw' }}
                            />
                            <Stack
                                direction="column"
                                spacing={0}
                                justifyContent="center"
                                alignItems="center"
                                flexWrap="wrap"
                            >
                                <label className="formLabel-USERS">Choose a City</label>
                            </Stack>
                            
                            <form className="formContainer-USERS" onSubmit={handleSubmit}>
                                <div className="formGroup-USERS">
                                        <label htmlFor="eventCity-USERS" className="formLabel-USERS">
                                        City:
                                        </label>
                                        <Fragment>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <input
                                            type="text"
                                            id="eventCity-USERS"
                                            className="formInput-USERS"
                                            onChange={onSChange}          
                                            value={userInput}
                                            placeholder=" 🔍 Select a City"
                                            style={{ flexGrow: 1, marginRight: '10px' }} // Remove inline width and padding to use the CSS class
                                            />
                                            <div className="selected-city-display">{city}</div>
                                        </div>
                                        {suggestionsListComponent(userInput, showmore)}
                                        </Fragment>
                                    </div>
                                
                                
                                <button type="submit" className="submitButton-USERS">
                                    3/4 Next
                                </button>
                            </form>
                        </div>
                    </div>
                );
            case 'Page 4':
                return (
                    <div>
                        <DesktopContainer />
                        <div className="mobile-container" style={{ overflow: 'hidden' }}>
                            <BackButton
                                onClick={() => setPage('Page 3')}
                                style={{ marginTop: '4vh', marginLeft: '3vw' }}
                            />
                            <Stack
                                direction="column"
                                spacing={0}
                                justifyContent="center"
                                alignItems="center"
                                flexWrap="wrap"
                            ><b>How to sign up?</b></Stack>
                            
                            <form className="formContainer-USERS" onSubmit={handleSubmit}>
                                <div className="formGroup-USERS">
                                    <label htmlFor="price-USERS" className="formLabel-USERS">
                                        Price:
                                    </label>
                                    <TextField
                                        type="number"
                                        id="price-USERS"
                                        className="formInput-USERS"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        required
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                              '& fieldset': {
                                                border: 'none', // removes the border
                                              },
                                              '&:hover fieldset': {
                                                border: 'none', // removes the border on hover
                                              },
                                              '&.Mui-focused fieldset': {
                                                border: 'none', // removes the border on focus
                                              },
                                            },
                                          }}
                                    />
                                </div>
                                <div className="formGroup-USERS">
                                    <label htmlFor="ticketURL-USERS" className="formLabel-USERS">
                                        Ticket Page URL:
                                    </label>
                                    <TextField
                                        type="text"
                                        id="ticketURL-USERS"
                                        className="formInput-USERS"
                                        value={ticketURL}
                                        onChange={(e) => setTicketURL(e.target.value)}
                                        required
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                              '& fieldset': {
                                                border: 'none', // removes the border
                                              },
                                              '&:hover fieldset': {
                                                border: 'none', // removes the border on hover
                                              },
                                              '&.Mui-focused fieldset': {
                                                border: 'none', // removes the border on focus
                                              },
                                            },
                                          }}
                                    />
                                </div>
                                <button type="submit" className="submitButton-USERS">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                );
                case 'Page 5':
                    return (
                        
                                <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={0}
                                mt={'40vh'}    
                                ><b>Thank you for submitting a new event!</b>
                                <b>Check back in 24hrs</b></Stack>
                                
                               
                            
                    );
            default:
                return null;
        }
    }

    return renderSwitch(page);
}

export default EventInfoPage;


// import React, { useState, useEffect } from 'react';
// import './EventInfoPage.css'; // Assume you have corresponding CSS
// import { Stack } from '@mui/material';
// import { DesktopContainer } from './components/DesktopContainer';
// function EventInfoPage() {
//     //these are all going to be test values between these comments
//         const Dump = {
//             events: [
//                 {eventName: "Lasertag bash", places: 1, Description:"This is going to be the best experience"}
//             ]


//         }



// //AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA DELETE THIS STUFF EVENTUALLY
//     const [answers, setAnswers] = useState({
//         numberOfPlaces: '',
//         typeOfPlaces: '',
//         eventsInterest: false,
//         date: '',
//         transportation: '',
//         numberOfPeople: '',
//         mood: '',
//         vibe: ''
//     });
//     const [currentPage, setCurrentPage] = useState("page1")
//     const [places, setPlaces] = useState([]);

//     const questionData = 
//         {page1: [
//             {
//                 question: "How many places would you like to visit today?",
//                 type: "button",
//                 options: ["1", "2", "3", "4"],
//                 name: "numberOfPlaces"
//             },
//             {
//                 question: "Experiences available",
//                 type: "dynamic-dropdown",
//                 name: "typeOfPlaces",
//                 data: "events"
//             },
//             // {
//             //     question: "Would you like to visit any events?",
//             //     type: "checkbox",
//             //     name: "eventsInterest"
//             // },
//             // {
//             //     question: "Please choose the date for your visit:",
//             //     type: "date",
//             //     name: "date"
//             // },
//             // {
//             //     question: "How will you be traveling?",
//             //     type: "dropdown",
//             //     options: ["Walk", "Transit", "Driving"],
//             //     name: "transportation"
//             // },
//             // {
//             //     question: "How many people are going?",
//             //     type: "number",
//             //     name: "numberOfPeople"
//             // },
//             // {
//             //     question: "What's your mood today?",
//             //     type: "button",
//             //     options: ["Happy", "Relaxed", "Excited"],
//             //     name: "mood"
//             // },
//             // {
//             //     question: "Choose the vibe for the outing:",
//             //     type: "button",
//             //     options: ["Chill", "Adventure", "Romantic"],
//             //     name: "vibe"
//             // }
//         ]};

//     const handleInputChange = (name, value) => {
//         setAnswers(prevAnswers => ({
//             ...prevAnswers,
//             [name]: value
//         }));
//     };

//     const fetchData = () => {
//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "text/plain");
//         myHeaders.append("Authorization", String(localStorage.getItem('accessToken')));

//         var raw = "{\r\n    \"PlaceID\": " + localStorage.getItem("place") + ", \r\n \"email\": \"" + String(localStorage.getItem('email')) + "\"\r\n}";

//         var requestOptions = {
//             method: 'GET',
//             headers: myHeaders,
//             //body: raw,
//             redirect: 'follow'
//         };

//         fetch("https://91k7tamkl6.execute-api.ca-central-1.amazonaws.com/Dev", requestOptions)
//             .then(response => response.text())
//             .then(result => {
//                 const data = JSON.parse(result);
//                 const parsedData = data.map(item => ({
//                     ...item,
//                     checked: item.checked === 'true',
//                 }));
//                 const filteredData = parsedData.filter(item => item.checked === true);
//                 setPlaces(filteredData);
//             })
//             .catch(error => console.log('error', error));
//     };

//     useEffect(() => {
//         console.log(questionData)
//     }, []);

//     const renderInput = (question) => {
//         switch (question.type) {
//             case 'number':
//             case 'text':
//                 return 
//             case 'date':
//                 return <input
//                     type={question.type}
//                     value={answers[question.name]}
//                     onChange={(e) => handleInputChange(question.name, e.target.value)}
//                 />;
//             case 'checkbox':
//                 return <input
//                     type="checkbox"
//                     checked={answers[question.name]}
//                     onChange={(e) => handleInputChange(question.name, e.target.checked)}
//                 />;
//             case 'dropdown':
//                 return (
//                     <select
//                         value={answers[question.name]}
//                         onChange={(e) => handleInputChange(question.name, e.target.value)}
//                     >
//                         <option value="">Select one</option>
//                         {question.options.map(option => (
//                             <option key={option} value={option}>{option}</option>
//                         ))}
//                     </select>
//                 );
//             case 'dynamic-dropdown':
//                 return (
//                     <select
//                         value={answers[question.name]}
//                         onChange={(e) => handleInputChange(question.name, e.target.value)}
//                     >
//                         <option value="">Select one</option>
//                         {places.map(place => (
//                             <option key={place.id} value={place.name}>{place.name}</option>
//                         ))}
//                     </select>
//                 );
//             case 'button':
//                 return question.options.map(option => (
//                     <button
//                         key={option}
//                         onClick={() => handleInputChange(question.name, option)}
//                         className={answers[question.name] === option ? 'active' : ''}
//                     >
//                         {option}
//                     </button>
//                 ));
//             default:
//                 return null;
//         }
//     };

//     return (
//         <div className="event-info-page">
//             {questionData[currentPage].map((question) => (

//                 <div key={question.name} className="question">
//                     <label>{question.question}</label>
//                     <Stack
//                         direction="row"
//                         spacing={0}
//                         justifyContent="center"
//                         alignItems="center"
//                         flexWrap="wrap"
//                     >
//                     {renderInput(question)}
//                     </Stack>
                    
//                 </div>
                
//             ))}
//             <button type="submit" className="submitButton-USERS">
//                 Next
//             </button>
//         </div>
        
//     );
// }

// export default EventInfoPage;
